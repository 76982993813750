var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", [_c("a-card", {
    staticStyle: {
      "overflow-x": "scroll"
    },
    attrs: {
      bordered: false
    }
  }, [_c("DTab", {
    staticStyle: {
      "overflow-x": "scroll"
    },
    attrs: {
      defaultIndex: _vm.defaultIndex,
      titles: _vm.titles,
      handleClick: _vm.handleClick,
      showNumb: true
    }
  }), _c("a-form", {
    attrs: {
      form: _vm.createForm,
      layout: "inline"
    }
  }, [_c("FormItems", {
    attrs: {
      searchKeys: _vm.searchKeys,
      getDataWithKey: _vm.getDataWithKey,
      onSelectChange: _vm.handleProvinceChange,
      onDateChange: _vm.onDateChange
    },
    scopedSlots: _vm._u([{
      key: "button",
      fn: function fn() {
        return [_c("a-button", {
          staticStyle: {
            "margin-bottom": "10px"
          },
          attrs: {
            type: "primary",
            size: "large"
          },
          on: {
            click: _vm.handleSearch
          }
        }, [_vm._v("查询")]), _c("a-button", {
          staticStyle: {
            "margin-left": "20px",
            border: "0",
            background: "#f5f5f5"
          },
          attrs: {
            size: "large"
          },
          on: {
            click: _vm.handleReset
          }
        }, [_vm._v("重置")])];
      },
      proxy: true
    }])
  })], 1)], 1), _c("a-card", {
    staticStyle: {
      "margin-top": "24px"
    },
    attrs: {
      bordered: false
    }
  }, [_c("a-button", {
    directives: [{
      name: "hasPermission",
      rawName: "v-hasPermission",
      value: ["limitedrelease:btn:add"],
      expression: "['limitedrelease:btn:add']"
    }],
    staticStyle: {
      "margin-bottom": "26px"
    },
    attrs: {
      type: "primary",
      size: "large"
    },
    on: {
      click: function click($event) {
        return _vm.addlimited("", 3);
      }
    }
  }, [_vm._v("新增")]), _c("a-table", {
    attrs: {
      pagination: false,
      dataSource: _vm.dataSource,
      columns: _vm.columns,
      rowKey: function rowKey(it, i) {
        return it.id;
      },
      loading: _vm.loading,
      scroll: {
        x: 1200
      }
    },
    scopedSlots: _vm._u([_vm._l(_vm.columns, function (_ref) {
      var key = _ref.key;
      return {
        key: key,
        fn: function fn(text, record, index) {
          return [key == "saletype" ? _c("span", [_c("span", [_vm._v(_vm._s(record.saletype === 1 ? "统一发售价" : "竞价"))])]) : key == "type" ? _c("span", [_c("span", [_vm._v(_vm._s(record.type === 1 ? "先到先得" : "抽签"))])]) : key == "begintime" ? _c("span", [_c("span", [_vm._v(_vm._s(record.begintime) + "-" + _vm._s(record.endtime))])]) : key == "count" ? _c("span", [_c("span", {
            staticStyle: {
              color: "#3270f5"
            },
            on: {
              click: function click($event) {
                return _vm.gotogoods(record);
              }
            }
          }, [_vm._v(_vm._s(record.count))])]) : key == "status" ? _c("span", [_vm.defaultIndex == 2 ? _c("span", [_vm._v(_vm._s(record.statusDesc))]) : _c("span", [_c("span", {
            directives: [{
              name: "show",
              rawName: "v-show",
              value: record.status == 1,
              expression: "record.status == 1"
            }]
          }, [_vm._v("进行中")]), _c("span", {
            directives: [{
              name: "show",
              rawName: "v-show",
              value: record.status == 2,
              expression: "record.status == 2"
            }]
          }, [_vm._v("未开始")]), _c("span", {
            directives: [{
              name: "show",
              rawName: "v-show",
              value: record.status == 3,
              expression: "record.status == 3"
            }]
          }, [_vm._v("已结束")])])]) : key == "operation" ? _c("span", [_c("span", [_c("a", {
            directives: [{
              name: "hasPermission",
              rawName: "v-hasPermission",
              value: ["limitedrelease:btn:watch"],
              expression: "['limitedrelease:btn:watch']"
            }],
            on: {
              click: function click($event) {
                return _vm.handleChange(record.id, 1);
              }
            }
          }, [_vm._v("查看")])]), _c("span", [_c("a-divider", {
            attrs: {
              type: "vertical"
            }
          }), _c("a", {
            directives: [{
              name: "hasPermission",
              rawName: "v-hasPermission",
              value: ["limitedrelease:btn:edit"],
              expression: "['limitedrelease:btn:edit']"
            }],
            on: {
              click: function click($event) {
                return _vm.handleupdate(record.id, 2);
              }
            }
          }, [_vm._v("修改")])], 1), _c("span", [_c("a-divider", {
            attrs: {
              type: "vertical"
            }
          }), _c("a", {
            directives: [{
              name: "hasPermission",
              rawName: "v-hasPermission",
              value: ["limitedrelease:btn:delete"],
              expression: "['limitedrelease:btn:delete']"
            }],
            on: {
              click: function click($event) {
                return _vm.handleDelete(record.id);
              }
            }
          }, [_vm._v("删除")])], 1)]) : _c("span", [_vm._v(_vm._s(_vm.filterRecordValue(record[key])))])];
        }
      };
    })], null, true)
  }), _c("pagination", {
    attrs: {
      total: _vm.total,
      page: _vm.page,
      size: _vm.size,
      onPageSizeChange: _vm.onPageSizeChange,
      onShowSizeChange: _vm.onShowSizeChange
    },
    on: {
      "update:page": function updatePage($event) {
        _vm.page = $event;
      },
      "update:size": function updateSize($event) {
        _vm.size = $event;
      }
    }
  })], 1), _c("DelUpdate", {
    attrs: {
      tipsDesc: _vm.tipsDesc,
      isDelete: true,
      comVisible: _vm.isDeleteVisible
    },
    on: {
      cancle: _vm.handleCancle,
      submit: _vm.handleSubmit
    }
  })], 1);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };