var columns = [{
  title: '活动名称',
  dataIndex: 'name',
  key: 'name',
  // width: '5%',
  scopedSlots: {
    customRender: 'name'
  }
}, {
  title: '活动时间',
  dataIndex: 'begintime',
  key: 'begintime',
  width: '25%',
  scopedSlots: {
    customRender: 'begintime'
  }
}, {
  title: '抢购方式',
  dataIndex: 'type',
  key: 'type',
  // width: '10%',
  scopedSlots: {
    customRender: 'type'
  }
}, {
  title: '发售方式',
  dataIndex: 'saletype',
  key: 'saletype',
  // width: '10%',
  scopedSlots: {
    customRender: 'saletype'
  }
}, {
  title: '指定商家',
  dataIndex: 'email',
  key: 'email',
  // width: '5%',
  scopedSlots: {
    customRender: 'email'
  },
  slots: {
    title: 'emailTit'
  }
}, {
  title: '指定商品',
  dataIndex: 'count',
  key: 'count',
  // width: '5%',
  scopedSlots: {
    customRender: 'count'
  }
}, {
  title: '状态',
  dataIndex: 'status',
  key: 'status',
  // width: '5%',
  scopedSlots: {
    customRender: 'status'
  }
}, {
  title: '操作',
  dataIndex: 'operation',
  key: 'operation',
  fixed: "right",
  width: 200,
  scopedSlots: {
    customRender: 'operation'
  }
}];
var emailcolumns = [{
  title: '商家名称',
  dataIndex: 'username',
  key: 'username',
  width: '25%',
  scopedSlots: {
    customRender: 'username'
  }
}, {
  title: '商家类型',
  dataIndex: 'registertype',
  key: 'registertype',
  width: '25%',
  scopedSlots: {
    customRender: 'registertype'
  }
}, {
  title: '邮箱',
  dataIndex: 'email',
  key: 'email',
  width: '25%',
  scopedSlots: {
    customRender: 'email'
  }
}, {
  title: '操作',
  dataIndex: 'operation',
  key: 'operation',
  // fixed: "right",
  // width: '18%',
  scopedSlots: {
    customRender: 'operation'
  }
}];
var idcolumns = [{
  title: '商品ID',
  dataIndex: 'id',
  key: 'id',
  // width: '5%',
  scopedSlots: {
    customRender: 'id'
  }
}, {
  title: '货号',
  dataIndex: 'sku',
  key: 'sku',
  // width: '5%',
  scopedSlots: {
    customRender: 'sku'
  }
}, {
  title: '商品图片',
  dataIndex: 'imgurls',
  key: 'imgurls',
  // width: '5%',
  scopedSlots: {
    customRender: 'imgurls'
  }
}, {
  title: '商品名称',
  dataIndex: 'name',
  key: 'name',
  // width: '5%',
  scopedSlots: {
    customRender: 'name'
  }
}, {
  title: '发售价($)',
  dataIndex: 'saleprice',
  key: 'saleprice',
  // width: '5%',
  scopedSlots: {
    customRender: 'saleprice'
  }
}, {
  title: '活动信息',
  dataIndex: 'activeinfo',
  key: 'activeinfo',
  // fixed: "right",
  // width: '18%',
  scopedSlots: {
    customRender: 'activeinfo'
  }
}, {
  title: '操作',
  dataIndex: 'operation',
  key: 'operation',
  // fixed: "right",
  // width: '18%',
  scopedSlots: {
    customRender: 'operation'
  }
}];
var pricecolumns = [{
  title: '尺码',
  dataIndex: 'size',
  key: 'size',
  // fixed: "right",
  // width: '18%',
  scopedSlots: {
    customRender: 'size'
  }
}, {
  title: '限量发售价($)',
  dataIndex: 'price',
  key: 'price',
  // fixed: "right",
  // width: '18%',
  scopedSlots: {
    customRender: 'price'
  }
}];
var columnsID = [{
  title: '商品ID',
  dataIndex: 'productid',
  key: 'productid',
  // width: '5%',
  scopedSlots: {
    customRender: 'productid'
  }
}, {
  title: '货号',
  dataIndex: 'sku',
  key: 'sku',
  // width: '5%',
  scopedSlots: {
    customRender: 'sku'
  }
}, {
  title: '商品图片',
  dataIndex: 'imgurl',
  key: 'imgurl',
  // width: '5%',
  scopedSlots: {
    customRender: 'imgurl'
  }
}, {
  title: '商品名称',
  dataIndex: 'name',
  key: 'name',
  // width: '5%',
  scopedSlots: {
    customRender: 'name'
  }
}, {
  title: '尺码',
  dataIndex: 'size',
  key: 'size',
  // width: '5%',
  scopedSlots: {
    customRender: 'size'
  }
}, {
  title: '限量发售价($)',
  dataIndex: 'price',
  key: 'price',
  // width: '5%',
  scopedSlots: {
    customRender: 'price'
  }
}, {
  title: '提报数量',
  dataIndex: 'count',
  key: 'count',
  // width: '5%',
  scopedSlots: {
    customRender: 'count'
  }
}];
var columnsGoods = [{
  title: '商品ID',
  dataIndex: 'productid',
  key: 'productid',
  // width: '5%',
  scopedSlots: {
    customRender: 'productid'
  }
}, {
  title: '商品名称',
  dataIndex: 'name',
  key: 'name',
  // width: '5%',
  scopedSlots: {
    customRender: 'name'
  }
}, {
  title: '货号',
  dataIndex: 'sku',
  key: 'sku',
  // width: '5%',
  scopedSlots: {
    customRender: 'sku'
  }
}, {
  title: '商品图片',
  dataIndex: 'productimgurl',
  key: 'productimgurl',
  // width: '5%',
  scopedSlots: {
    customRender: 'productimgurl'
  }
}, {
  title: '限量发售价($)',
  dataIndex: 'prices',
  key: 'prices',
  // width: '5%',
  scopedSlots: {
    customRender: 'prices'
  }
}];
export { columns, emailcolumns, idcolumns, pricecolumns, columnsID, columnsGoods };